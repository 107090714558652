import { createEffect, onCleanup } from "solid-js";

export function docHeight() {
  const documentHeight = () => {
    document.documentElement.style.setProperty(
      "--doc-height",
      `${window.innerHeight}px`
    );
  };

  return createEffect(() => {
    window.addEventListener("resize", documentHeight);
    documentHeight();

    onCleanup(() => {
      window.removeEventListener("resize", documentHeight);
    });
  });
}
