import {
  Accessor,
  createEffect,
  createSignal,
  onCleanup,
  ParentComponent,
} from "solid-js";
import { createContext, useContext } from "solid-js";
import { Application, Settings as DbSettings, db } from "~/database/database";
import { liveQuery } from "dexie";

type Settings = {
  current_app?: number;
};

const ApplicationContext = createContext<{
  app?: Accessor<Application | undefined>;
  settings?: Accessor<Settings | undefined>;
}>();

export const ApplicationProvider: ParentComponent = (props) => {
  const [settings, setSettings] = createSignal<Settings>();
  const [app, setApp] = createSignal<Application>();

  createEffect(async () => {
    const asObject = (settings: DbSettings[]) =>
      settings.reduce(
        (prev, current) => ({ ...prev, [current.prop]: current.value }),
        {} as Settings
      );

    const settingsObservable = liveQuery(() => db.settings.toArray());

    const subscription = settingsObservable.subscribe({
      next: (result) => setSettings(asObject(result)),
    });

    onCleanup(() => {
      subscription.unsubscribe();
    });
  });

  createEffect(async () => {
    if (!settings()?.current_app) {
      return;
    }

    const app = await db.applications
      .where("id")
      .equals(settings()!.current_app!)
      .first();
    setApp(app);
  });

  return (
    <ApplicationContext.Provider value={{ app, settings }}>
      {props.children}
    </ApplicationContext.Provider>
  );
};

export const useApp = () => {
  const { app } = useContext(ApplicationContext);

  return app as Accessor<Application | undefined>;
};
