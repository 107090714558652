import { Component, createSignal, JSX, ParentProps } from "solid-js";

export type ToastProps = {
  title?: string;
  showIcon?: boolean;
} & JSX.HTMLAttributes<HTMLDivElement>;

const Toast: Component<ToastProps> = (props) => {
  return <div {...props}></div>;
};

export default Toast;
