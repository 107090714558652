import {
  Accessor,
  createEffect,
  createSignal,
  ParentComponent,
  Suspense,
} from "solid-js";
import { createContext, useContext } from "solid-js";
import * as Realm from "realm-web";
import { useApp } from "~/app/Application/ApplicationProvider";
import { useToast } from "~/app/Toast/ToastProvider";
import { Application } from "~/database/database";

const RealmContext = createContext();

type Roles = "admin" | "guest";

export const makeCredentials = (application: Application) => {
  switch (application.authentication) {
    case "anonymous":
      return Realm.Credentials.anonymous();
    case "emailPassword":
      return Realm.Credentials.emailPassword(
        application.email as string,
        application.password as string
      );
    default:
      throw new Error("Unsuported authentication method");
  }
};

export const testLogin = async (application: Application) => {
  const app = new Realm.App({ id: application.realm });

  const credentials = makeCredentials(application);

  const user = await app.logIn(credentials);

  await user.logOut();

  await app.removeUser(user);

  if (!user?.id) {
    throw new Error("Unable to verify login.");
  }

  return true;
};

export const RealmProvider: ParentComponent = (props) => {
  const [app, setApp] = createSignal<Realm.App>();
  const { catchToast } = useToast();

  createEffect(
    async () =>
      await catchToast(async () => {
        const appSettings = useApp();

        if (!appSettings()) {
          return;
        }

        const app = new Realm.App({ id: appSettings()!.realm });

        if (
          !app.currentUser ||
          app.currentUser?.profile?.email !== appSettings()!.email
        ) {
          const credentials = makeCredentials(appSettings()!);

          await app.logIn(credentials);
        }

        setApp(app);
      })
  );

  return (
    <Suspense fallback={<LoadingCircle />}>
      <RealmContext.Provider value={app}>
        {props.children}
      </RealmContext.Provider>
    </Suspense>
  );
};

export const useRealm = () => {
  const app = useContext(RealmContext);

  return app as Accessor<Realm.App>;
};

export const useMongo = <T extends globalThis.Realm.Services.MongoDB.Document>(
  database: string,
  collection: string
) => {
  const app = useRealm();

  if (!app()) {
    return undefined;
  }

  const mongo = app().currentUser!.mongoClient("mongodb-atlas");

  return mongo.db(database).collection<T>(collection);
};

export const currentUserIs = (role: Roles) => {
  const app = useRealm();

  const userRoles = app().currentUser?.customData.roles as Roles[] | undefined;
  return userRoles?.includes(role);
};

function LoadingBasketBall() {
  return (
    <div class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
      <div class="text-4xl animate__animated animate__infinite animate__bounce [--animate-duration:2s] translate-y-80">
        🏀
      </div>
    </div>
  );
}

function LoadingCircle() {
  return (
    <div class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
      <div class="text-4xl animate-spin">⚆</div>
    </div>
  );
}
