// @refresh reload
import { Suspense } from "solid-js";
import {
  Body,
  ErrorBoundary,
  FileRoutes,
  Head,
  Html,
  Meta,
  Routes,
  Scripts,
  Title,
} from "solid-start";
import { ApplicationProvider } from "./app/Application/ApplicationProvider";
import { RealmProvider } from "./app/Realm/RealmProvider";
import { ToastProvider } from "./app/Toast/ToastProvider";
import "./root.css";
import "animate.css";
import "./app/Sentry/sentry";
import { docHeight } from "./app/Helpers/docHeight";
import ErrorMessage from "./components/ErrorMessage";

export default function Root() {
  docHeight();

  return (
    <Html lang="en" class="overscroll-none">
      <Head>
        <Title>365 Photos</Title>
        <Meta charset="utf-8" />
        <Meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Body>
        <Suspense>
          <ErrorBoundary
            fallback={(e, reset) => {
              return <ErrorMessage error={e} reset={reset} />;
            }}
          >
            <ToastProvider>
              <ApplicationProvider>
                <RealmProvider>
                  <Routes>
                    <FileRoutes />
                  </Routes>
                </RealmProvider>
              </ApplicationProvider>
            </ToastProvider>
          </ErrorBoundary>
        </Suspense>
        <Scripts />
      </Body>
    </Html>
  );
}
