import Dexie, { Table } from "dexie";

export interface Application {
  id?: number;
  authentication: "anonymous" | "emailPassword";
  realm: string;
  email?: "string";
  password?: "string";
}

export interface Settings {
  prop: string;
  value: any;
}

class Database extends Dexie {
  // 'applications' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  applications!: Table<Application>;
  settings!: Table<Settings>;

  constructor() {
    super("356photos");

    this.version(1).stores({
      applications: "++id, authentication, realm", // Primary key and indexed props
      settings: "prop, value", // Primary key and indexed props
    });
  }
}

export const db = new Database();
