import { createSignal, For, ParentComponent, Show } from "solid-js";
import { createContext, useContext } from "solid-js";
import Toast, { ToastProps } from "./Toast";

const ToastContext = createContext<{
  addToast: (toast: ToastProps) => any[];
}>({ addToast: () => [] });

export const ToastProvider: ParentComponent = (props) => {
  const [toasts, setToasts] = createSignal<ToastProps[]>([]);

  const addToast = (toast: ToastProps) => setToasts([...toasts(), toast]);
  const removeToast = (toast: ToastProps) =>
    setToasts(toasts().filter((t) => t !== toast));

  return (
    <ToastContext.Provider value={{ addToast }}>
      {props.children}
      <Show when={toasts().length > 0}>
        <div class="fixed bottom-0 right-0">
          <For each={toasts()}>
            {(toast) => <Toast onClick={() => removeToast(toast)} {...toast} />}
          </For>
        </div>
      </Show>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const { addToast } = useContext(ToastContext);

  const catchToast = async (callback: any) => {
    try {
      return await callback();
    } catch (error: any) {
      addToast({
        children: error.message,
        class: "bg-red-300 m-2 p-4 rounded max-w-xs shadow cursor-pointer",
      });
      throw error;
    }
  };

  const toast = (message: any) => {
    addToast({
      children: message,
    });
  };

  return { addToast, catchToast, toast };
};

// toast(
//   <div class="bg-green-500 m-2 p-4 rounded max-w-xl shadow cursor-pointer flex gap-4 items-center">
//     <img src={/*@once*/ URL.createObjectURL(file)} class="w-24" />
//     Photo was successfuly uploaded.
//   </div>
// );

// toast(successPhotoUploadToast({ file }));
// function successPhotoUploadToast(props) {
//   return (
//     <div class="bg-green-500 m-2 p-4 rounded max-w-xl shadow cursor-pointer flex gap-4 items-center">
//       <img src={/*@once*/ URL.createObjectURL(props.file)} class="w-24" />
//       Photo was successfuly uploaded.
//     </div>
//   );
// }
